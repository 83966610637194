import { Button, Col, Row } from "antd"
import { useNavigate } from "react-router-dom"
import { useLinkClickHandler, useRoutes } from "react-router-dom"



export const CustomButton = (props: any) => {

    const goToLocation = useNavigate()


    return (<div style={{ display: "flex", flexDirection: "column", alignContent: "center" }}>
        <Button type="text" className="MenuButton" onClick={() => goToLocation(props.to)}>{props.name}</Button>
    </div>)
}