import { Button, Form, Input, message } from "antd"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../AuthProvider/AuthProvider";

export const Login = () => {
  const goToLocation = useNavigate();
  const auth = useAuth();
  const [loginForm] = Form.useForm();
  const handleLogin = () => {
    loginForm
      .validateFields()
      .then((values) => {
        auth.login(values.email, values.password);
      })
      .catch((err) => {
        console.error(err);
        message.error("Failed to login.");
      });
  };
  return (
    <div style={{ margin: "20vw" }}>
      <h1>LOGIN</h1>
      <Form form={loginForm}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Email address required " },
            { type: "email", message: "Please use a valid email" },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Password required " }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
      </Form>
      <Button onClick={() => handleLogin()}>Login</Button>
    </div>
  );
};