import { CopyrightOutlined } from "@ant-design/icons";
import { Row } from "antd";

export const CopyrightBar = () => {
  return (
    <Row>
      <div
        className="BlockWidget"
        style={{ width: "100%", textAlign: "center", color: "white" }}
      >
        <b>Low Energy Construction</b> <CopyrightOutlined /> Copyright 2022. All
        Rights Reserved.
      </div>
    </Row>
  );
};
