import { LoadingOutlined } from "@ant-design/icons";
import { BackTop, Spin } from "antd";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { About } from "./About/About";
//import { AdminTools } from "./AdminTools/AdminTools";
import "./App.less";
import { AuthProvider } from "./AuthProvider/AuthProvider";
import { Contact } from "./Contact/Contact";
import { CurrentProjects } from "./CurrentProjects/CurrentProjects";
import { Footer } from "./Footer/Footer";
import { Home } from "./Home/Home";
import { HowItWorks } from "./HowItWorks/HowItWorks";
import { Login } from "./Login/Login";
import { MenuBar } from "./MenuBar/MenuBar";

import { RequireAuth } from "./PrivateRoute/PrivateRoute";
import { Project } from "./Project/Project";

const AdminTools = React.lazy(() => import("./AdminTools/AdminTools"));
const spinIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function App() {
  return (
    <Router>
      <AuthProvider>
        <RecoilRoot>
          <div style={{ height: "100vh", width: "100vw", overflow: "scroll" }}>
            <div style={{ width: "100vw", overflow: "hidden" }}>
              <MenuBar />
            </div>
            <BackTop />
            <div>
              <Routes>
                <Route index element={<Home />} />
                <Route path="/current-projects" element={<CurrentProjects />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/project/:projectId" element={<Project />} />

                <Route
                  path="/administration-tools"
                  element={
                    <RequireAuth>
                      <Suspense fallback={<Spin indicator={spinIcon} />}>
                        <AdminTools />
                      </Suspense>
                    </RequireAuth>
                  }
                />
              </Routes>
            </div>
            <Footer />
          </div>
        </RecoilRoot>
      </AuthProvider>
    </Router>
  );
}

export default App;
