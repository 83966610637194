import React, { useContext, useEffect, useState } from "react";
import { auth } from "../link/link";
import { useNavigate } from "react-router";
import { message } from "antd";
import "firebase/auth";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { AuthContextType } from "../typeDefs";

const AuthContext = React.createContext<AuthContextType>(null!);
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: { children: any }) => {
  const goTo = useNavigate();

  const [currentUser, setCurrentUser] = useState<any>(null);

  const signUp = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            goTo("/administration-tools");
            return null;
          })
          .catch((err: any) => {
            message.error("Failed to login... Do you have an account?");
            return null;
          });
      })
      .catch((err: any) => console.error(err));
  };

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password).then(
      () => {
        goTo("/administration-tools");
        return true;
      },
      (err: any) => {
        message.error("Failed to login... Do you have an account?");
        return null;
      }
    );
  };

  const logout = () => {
    return auth
      .signOut()
      .then(() => {
        goTo("/");
        setCurrentUser(null);
      })
      .catch((err: any) => console.error(err));
  };

  const sendPasswordReset = async (email: any) => {
    return await sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    login,
    logout,
    sendPasswordReset,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
