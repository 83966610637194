import { Col, Image, Row } from "antd";
import { useRecoilValue } from "recoil";
import { windowState } from "../recoilState/recoilState";
import image from "./About.png";
import wellBuilt from "./fjfgkjgkgk.png";
import quality from "./vfhncghj.png";

export const About = () => {
  const { width, height } = useRecoilValue(windowState);
  return (
    <div>
      {height > width ? (
        <Row style={{ zIndex: 10, position: "relative" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image src={image} height="100%" preview={false} />
          </div>
        </Row>
      ) : (
        <Row style={{ zIndex: 10, position: "relative" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image src={image} width="100%" preview={false} />
          </div>
        </Row>
      )}
      <div
        style={{
          zIndex: 15,
          position: "relative",
          backgroundColor: "white",
          paddingLeft: width < 1300 ? "5%" : "20%",
          paddingRight: width < 1300 ? "5%" : "20%",
          paddingTop: "2rem",
        }}
      >
        <Row>
          <h1 style={{ fontWeight: "bold" }}>ABOUT US</h1>
        </Row>
        <Row>
          <div style={{ padding: "2rem" }}>
            {width < 900 ? (
              <>
                <Col span={24}>
                  <Image src={wellBuilt} width="100%" preview={false} />
                </Col>
                <Col span={24}>
                  <h1>What is “Low Energy Construction”?</h1>
                  <p>
                    Low Energy Construction is a sweet spot between New
                    Zealand’s current standard of building and Internationally
                    recognized standards of excellence in warm, healthy homes.
                    Conferences in Australia, Switzerland, Germany and America
                    exposed the directors to methods and materials in
                    construction that allowed home owners to live in houses that
                    were cheap to heat and provided a stable temperature
                    throughout the house all year round. Passivehaus ( see
                    passivehaus in 90 seconds) is the gold standard in thermally
                    efficient construction, so after training as Passivehaus
                    tradesman, Low Energy Construction Directors looked at how
                    to bring the benefits of these methods and technologies to
                    the houses of those that need it the most, the elderly and
                    families with small children. Low Energy Construction aims
                    at getting 80% of the benefits of Energy efficient building
                    at just a 20% increase in building cost. This is achieved by
                    utilizing Closed cell insulation, UPVC joinery, Balanced
                    Heat Recovery ventilation and subtle changes in construction
                    details.
                  </p>
                </Col>
              </>
            ) : (
              <div>
                <Row>
                  <Col span={12}>
                    <Image src={wellBuilt} width="90%" preview={false} />
                  </Col>
                  <Col span={12}>
                    <h1>What is “Low Energy Construction”?</h1>
                    <p>
                      The concept behind Low Energy Construction is that an air
                      tight, well insulated house will retain the energy in it.
                      Most of the energy required to heat these buildings is
                      generated by people living, breathing and cooking in them.
                      To achieve such high energy retention requires a
                      commitment to detail that has been lacking previously in
                      New Zealand construction. Modern construction methods mean
                      that the competition in construction is “How quick can you
                      get it done?”, whereas in Low Energy Construction the
                      competition is “How well can you build it?”
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Row>
        <Row>
          <div style={{ padding: "2rem" }}>
            {width < 900 ? (
              <div>
                <Image src={quality} width="90%" preview={false} />
                <h1>Quality is Fundamental</h1>
                <p>
                  We believe these methods and results are achievable by all
                  house constructors and so offer consultation services and
                  design details so that any new build can benefit from our
                  lessons learnt. Low Energy Construction is about building
                  houses today that will continue to provide a warm, healthy
                  living environment for the life of the building, well
                  insulated against future energy price hikes! If you would like
                  to benefit from the lessons we have learnt, or would simply
                  like to know more, get in contact and we would love to help
                  you achieve the results we have.
                </p>
              </div>
            ) : (
              <Row>
                <Col span={12}>
                  <h1>Quality is Fundamental</h1>
                  <p>
                    We believe these methods and results are achievable by all
                    house constructors and so offer consultation services and
                    design details so that any new build can benefit from our
                    lessons learnt. Low Energy Construction is about building
                    houses today that will continue to provide a warm, healthy
                    living environment for the life of the building, well
                    insulated against future energy price hikes! If you would
                    like to benefit from the lessons we have learnt, or would
                    simply like to know more, get in contact and we would love
                    to help you achieve the results we have.
                  </p>
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                  <Image src={quality} width="80%" preview={false} />
                </Col>
              </Row>
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};
