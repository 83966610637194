import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAslA40bf0Vz3J79vDrChmHDnBcxa6u_l0",
  authDomain: "lowenergyconstruction.firebaseapp.com",
  projectId: "lowenergyconstruction",
  storageBucket: "lowenergyconstruction.appspot.com",
  messagingSenderId: "208785797610",
  appId: "1:208785797610:web:26899f3b964fbd62d50293",
  measurementId: "G-KJK2BR43X8",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

