import { Button, Col, Divider, Image, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { projectsState, windowState } from "../recoilState/recoilState";
import bathroom from "./bath.png";
import bed from "./bed.png";
import car from "./car.png";
import lounge from "./Lounge.png";
import mainImg from "./groynes 501.jpg";
import img2 from "./groynes 501 v1.jpg";
import img3 from "./groynes 501- unit 1 cut section v1.jpg";
import img4 from "./groynes 501- unit 1 elevation v2.jpg";
import img5 from "./groynes 501- unit 2 elevation v2 (1).jpg";
import img6 from "./groynes 501- unit 2 elevation v3 (1).jpg";
import img7 from "./groynes 501- unit 4 elevation v1.jpg";
import img8 from "./groynes 501- unit 4 elevation v2.jpg";
import img9 from "./groynes 501- unit 4 elevation v4 (1).jpg";
import plan1 from "./Plan1.png";
import plan2 from "./Plan2.png";
import plan3 from "./Plan3.png";
import plan4 from "./Plan4.png";
import plan5 from "./Plan5.png";
import plan6 from "./Plan6.png";

const additionalImages = [
  { src: mainImg, width: 2628, height: 2148 },
  { src: img2, width: 2628, height: 2148 },
  { src: img3, width: 2178, height: 2352 },
  { src: img4, width: 2628, height: 2148 },
  { src: img5, width: 1314, height: 1062 },
  { src: img6, width: 2628, height: 2148 },
  { src: img7, width: 2628, height: 2148 },
  { src: img8, width: 2628, height: 2148 },
  { src: img9, width: 2628, height: 2148 },
  { src: plan1, width: 1304, height: 978 },
  { src: plan2, width: 1314, height: 978 },
  { src: plan3, width: 1304, height: 978 },
  { src: plan4, width: 1304, height: 978 },
  { src: plan5, width: 1304, height: 978 },
  { src: plan6, width: 1304, height: 978 },
];

const bullets = [
  "Spray Foam Insulation",
  "Eco Friendly",
  "Custom Heat Transfer System",
  "Vaulted Ceiling in Living Area",
  "Natural Non Toxic Paint",
  "Fully Fenced and Landscaped",
  "Vinyl Planked Living Area",
  "Tiled Master Shower",
  "Seperate Toilet",
  "Outdoor Decking Included",
];

export const Project = () => {
  const location = useLocation();
  const goTo = useNavigate();
  const { height, width } = useRecoilValue(windowState);
  const projects = useRecoilValue(projectsState);
  let dollarNZ = Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
  });

  return (
    <div style={{ marginTop: "120px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: width < 1300 ? "5%" : "20%",
          paddingRight: width < 1300 ? "5%" : "20%",
          marginBottom: "4rem",
        }}
      >
        {projects &&
          projects
            .filter((item) => {
              return item.id === location.pathname.split("t/")[1];
            })
            .map((item) => {
              return (
                <>
                  <div>
                    {width < height ? (
                      <Image src={mainImg} width="95vw" />
                    ) : (
                      <Image src={mainImg} height="60vh" />
                    )}
                  </div>
                  <div style={{ padding: "5vw", paddingBottom: "1vw" }}>
                    <h1>{item.title}</h1>
                    <Divider />
                    {item.sold && (
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "5rem",
                          color: "red",
                          textAlign: "center",
                          fontFamily: "fantasy",
                          fontVariant: "contextual",
                        }}
                      >
                        SOLD
                      </div>
                    )}
                    <h2>{item.tagLine}</h2>
                    <p>{item.address}</p>
                    <div>
                      <Divider />
                      <Row>
                        <Col span={6}>
                          <div style={{ textAlign: "center" }}>
                            <Image src={lounge} width="60px" preview={false} />
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                          >{`${item.livingSpace} Living Spaces`}</div>
                        </Col>
                        <Col span={6}>
                          <div style={{ textAlign: "center" }}>
                            <Image
                              src={bathroom}
                              width="60px"
                              preview={false}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            {`${item.bathrooms} Bathrooms`}
                          </div>
                        </Col>
                        <Col span={6}>
                          <div style={{ textAlign: "center" }}>
                            <Image src={bed} width="60px" preview={false} />
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                          >{`${item.bedrooms} Bedrooms`}</div>
                        </Col>
                        <Col span={6}>
                          <div style={{ textAlign: "center" }}>
                            <Image src={car} width="60px" preview={false} />
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                          >{`${item.garageCars} Garage Spaces`}</div>
                        </Col>
                      </Row>
                      <Divider />
                    </div>

                    <p>{item.intro}</p>
                    <p>{item.content}</p>
                    <div style={{ paddingLeft: "2vw" }}>
                      <ul>
                        {bullets.map((text, idx) => (
                          <li key={idx}>{text}</li>
                        ))}
                      </ul>
                    </div>
                    <Divider />
                    <h2 style={{ fontSize: "2rem", textAlign: "center" }}>
                      {dollarNZ.format(item.price)}
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={() => goTo("/contact-us")}
                      >
                        Enquire
                      </Button>
                    </div>
                    <Divider />
                    <div
                      style={{
                        width: width > height ? width * 0.6 : width * 0.9,

                        alignSelf: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          overflowX: "scroll",
                        }}
                      >
                        {additionalImages &&
                          additionalImages.map((image, idx) => {
                            return (
                              <div key={idx} style={{ paddingRight: ".2rem" }}>
                                <Image
                                  src={image.src}
                                  height={200}
                                  width={(200 / image.height) * image.width}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
      </div>
    </div>
  );
};
