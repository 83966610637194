import { Button } from "antd";
import { useRecoilValue } from "recoil";
import { windowState } from "../recoilState/recoilState";

export const Contact = () => {
  const { width, height } = useRecoilValue(windowState);

  return (
    <>
      <div
        style={{
          paddingLeft: width > 600 ? "20vw" : "5vw",
          paddingRight: width > 600 ? "20vw" : "5vw",
          paddingTop: height > 700 ? "15vh" : "20vh",
          paddingBottom: height > 700 ? "15vh" : "20vh",
        }}
      >
        <div
          style={{
            padding: "2rem",
          }}
        >
          <h1 style={{ fontWeight: "bold" }}>CONTACT US</h1>
          <h2>Phone: 0224532919</h2>

          <h2>Email: sue@lowenergyconstruction.co.nz</h2>
          <Button
            type="primary"
            onClick={() =>
              window.open("mailto:sue@lowenergyconstruction.co.nz")
            }
          >
            Email Us
          </Button>
        </div>
      </div>
    </>
  );
};
