import { Row } from "antd";
import { ProjectCarousel } from "../ProjectCarousel/ProjectCarousel";

export const CurrentProjects = () => {
  return (
    <div style={{ backgroundColor: "white", paddingTop: "120px" }}>
      <Row>
        <ProjectCarousel />
      </Row>
    </div>
  );
};
