import { Button, Col, Image, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { projectsState, windowState } from "../recoilState/recoilState";

import houseSrc from "./groynes 501.jpg";

export const ProjectCarousel = () => {
  const goToLocation = useNavigate();
  const { height, width } = useRecoilValue(windowState);
  let flip = true;

  const currentProjects = useRecoilValue(projectsState);

  return (
    <div
      style={{
        width: "100vw",
      }}
    >
      <div>
        {currentProjects.length >= 1 &&
          currentProjects.map((item, idx) => {
            flip ? (flip = false) : (flip = true);
            if (width > height) {
              return (
                <div
                  key={idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "2%",
                  }}
                >
                  <div style={{ alignSelf: "center", width: "60%" }}>
                    <Row>
                      {!flip ? (
                        <>
                          <Col span={12}>
                            <div style={{ display: "block" }}>
                              {width < height ? (
                                <Image
                                  preview={false}
                                  src={item.imageOne}
                                  width="100%"
                                />
                              ) : (
                                <Image
                                  preview={false}
                                  src={houseSrc}
                                  width="100%"
                                />
                              )}
                            </div>
                          </Col>
                          <Col span={12} style={{ paddingLeft: "2rem" }}>
                            <h1>{item.title}</h1>
                            {item.sold && (
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "2rem",
                                  color: "red",
                                  textAlign: "center",
                                  fontFamily: "fantasy",
                                  fontVariant: "contextual",
                                }}
                              >
                                SOLD
                              </div>
                            )}
                            <h2>{item.tagLine}</h2>
                            <h2>{item.intro}</h2>
                            <div
                              style={{
                                display: "block",
                                paddingTop: "2rem",
                              }}
                            >
                              <Button
                                size="large"
                                type="primary"
                                onClick={() =>
                                  goToLocation(`/project/${item.id}`)
                                }
                              >
                                View More
                              </Button>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={12} style={{ paddingLeft: "2rem" }}>
                            <h1>{item.title}</h1>
                            {item.sold && (
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "2rem",
                                  color: "red",
                                  textAlign: "center",
                                  fontFamily: "fantasy",
                                  fontVariant: "contextual",
                                }}
                              >
                                SOLD
                              </div>
                            )}
                            <h2>{item.tagLine}</h2>
                            <h2>{item.intro}</h2>
                            <div
                              style={{
                                display: "block",
                                paddingTop: "2rem",
                              }}
                            >
                              <Button
                                size="large"
                                type="primary"
                                onClick={() =>
                                  goToLocation(`/project/${item.id}`)
                                }
                              >
                                View More
                              </Button>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div style={{ display: "block" }}>
                              {width < height ? (
                                <Image
                                  preview={false}
                                  src={houseSrc}
                                  width="100%"
                                />
                              ) : (
                                <Image
                                  preview={false}
                                  src={houseSrc}
                                  width="100%"
                                />
                              )}
                            </div>
                          </Col>
                        </>
                      )}
                      {/* <Col span={12}>
                        <div style={{ display: "block" }}>
                          {width < height ? (
                            <Image
                              preview={false}
                              src={item.imageOne}
                              width="100%"
                            />
                          ) : (
                            <Image
                              preview={false}
                              src={item.imageOne}
                              width="100%"
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={12} style={{ paddingLeft: "2rem" }}>
                        <h1>{item.title}</h1>
                        {item.sold && (
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "2rem",
                              color: "red",
                              textAlign: "center",
                              fontFamily: "fantasy",
                              fontVariant: "contextual",
                            }}
                          >
                            SOLD
                          </div>
                        )}
                        <h2>{item.tagLine}</h2>
                        <h2>{item.intro}</h2>
                        <div
                          style={{
                            display: "block",
                            paddingTop: "2rem",
                          }}
                        >
                          <Button
                            size="large"
                            type="primary"
                            onClick={() => goToLocation(`/project/${item.id}`)}
                          >
                            View More
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "5rem",
                  }}
                >
                  <div style={{ padding: "5%" }}>
                    <div style={{ display: "block" }}>
                      <Image preview={false} src={houseSrc} width="100%" />
                    </div>
                    <h1>{item.title}</h1>
                    {item.sold && (
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "2rem",
                          color: "red",
                          textAlign: "center",
                          fontFamily: "fantasy",
                          fontVariant: "contextual",
                        }}
                      >
                        SOLD
                      </div>
                    )}
                    <h2>{item.tagLine}</h2>
                    <h2>{item.intro}</h2>
                    <div style={{ display: "block", paddingTop: "2.5rem" }}>
                      <Button
                        size="large"
                        type="primary"
                        onClick={() => goToLocation(`/project/${item.id}`)}
                      >
                        View More
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
