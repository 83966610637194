import { Col, Row } from "antd";
import { useRecoilValue } from "recoil";
import { ContactForm } from "../ContactForm/ContactForm";
import { CopyrightBar } from "../CopyrightBar/CopyrightBar";
import { windowState } from "../recoilState/recoilState";
import { Stories } from "../Stories/Stories";

export const Footer = () => {
  const { width, height } = useRecoilValue(windowState);
  return (
    <>
      {height > width ? (
        <>
          <Row>
            <ContactForm />
          </Row>
        </>
      ) : (
        <Row>
          <Col span={12}>
            <Stories />
          </Col>
          <Col span={12}>
            <ContactForm />
          </Col>
        </Row>
      )}
      <CopyrightBar />
    </>
  );
};
