import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, message, Space } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { useState } from "react";
import { db } from "../link/link";

export const ContactForm = () => {
  const [contactForm] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleContactFrom = () => {
    contactForm
      .validateFields()
      .then((values) => {
        const newId = nanoid();
        setDoc(doc(db, `messages/${newId}`), {
          id: newId,
          responded: false,
          ...values,
        });
      })
      .then(() => {
        setIsSubmitted(true);
        message.success("Thanks, we will be in touch with you soon.");
      })
      .catch((err) => {
        console.error(err);
        message.error("Failed to send your message. Please try again later.");
      });
  };

  return (
    <div className="BlockWidget">
      <h3>Leave us a message</h3>
      {isSubmitted ? (
        <div
          style={{
            alignSelf: "center",
            padding: "2%",
            width: "80%",
            maxWidth: "600px",
          }}
        >
          Thanks, we have received your message and will be in touch soon. Kind
          regards, The team at Low Energy Construction
        </div>
      ) : (
        <div
          style={{
            alignSelf: "center",
            padding: "2%",
            width: "80%",
            maxWidth: "600px",
          }}
        >
          <Form form={contactForm} layout="vertical">
            <Form.Item
              name="name"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Name" allowClear />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Phone" allowClear />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { type: "email", message: "Please use a valid email address" },
              ]}
            >
              <Input placeholder="Email address" allowClear />
            </Form.Item>
            <Form.Item
              name="text"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input.TextArea
                placeholder="What would you like to enquire about?"
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            style={{ maxWidth: "300px" }}
            onClick={() => handleContactFrom()}
          >
            Submit
          </Button>
        </div>
      )}
      <div style={{ textAlign: "center" }}>
        <Space align="center">
          <Button
            type="text"
            icon={
              <FacebookOutlined
                style={{ fontSize: "50px", color: "whitesmoke" }}
              />
            }
            onClick={() =>
              window.open(
                "https://www.facebook.com/Low-Energy-Construction-108171995220850"
              )
            }
          />
          {/* <Button
            type="text"
            icon={
              <TwitterOutlined
                style={{ fontSize: "50px", color: "whitesmoke" }}
              />
            }
          />
          <Button
            type="text"
            icon={
              <InstagramOutlined
                style={{ fontSize: "50px", color: "whitesmoke" }}
              />
            }
          /> */}
        </Space>
      </div>
    </div>
  );
};
