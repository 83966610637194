import { Col, Image, Row } from "antd";
import { useRecoilValue } from "recoil";
import { windowState } from "../recoilState/recoilState";
import image from "./How.png";
import envelope from "./envelope.png";
import windowBreak from "./window.png";

export const HowItWorks = () => {
  const { width, height } = useRecoilValue(windowState);

  return (
    <div>
      {height > width ? (
        <Row style={{ zIndex: 10, position: "relative" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image src={image} height="100%" preview={false} />
          </div>
        </Row>
      ) : (
        <Row style={{ zIndex: 10, position: "relative" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image src={image} width="100%" preview={false} />
          </div>
        </Row>
      )}
      <div
        style={{
          zIndex: 15,
          position: "relative",

          backgroundColor: "white",
          paddingLeft: width < 1300 ? "5%" : "20%",
          paddingRight: width < 1300 ? "5%" : "20%",
          paddingTop: "2rem",
        }}
      >
        <div>
          <Row>
            <h1 style={{ fontWeight: "bold" }}>HOW IT WORKS</h1>
          </Row>
          {height > width ? (
            <>
              <Row>
                <h2>01 FULLY INSULATED BUILDING ENVELOPE</h2>
                <p>
                  This requires very specific details, particularly at
                  intersections between floor and walls or walls and roof. High
                  performance windows are a must and they must be positioned in
                  the centre of the insulated wall. Walls will be thicker than
                  standard construction to allow for the extra Insulation.
                </p>
              </Row>
              <Row>
                <div style={{ padding: "10%", paddingTop: "0%" }}>
                  <Image src={envelope} width="100%" preview={false} />
                </div>
              </Row>
            </>
          ) : (
            <Row>
              <Col span={12}>
                <h2>01 FULLY INSULATED BUILDING ENVELOPE</h2>
                <p>
                  This requires very specific details, particularly at
                  intersections between floor and walls or walls and roof. High
                  performance windows are a must and they must be positioned in
                  the centre of the insulated wall. Walls will be thicker than
                  standard construction to allow for the extra Insulation.
                </p>
              </Col>
              <Col span={12}>
                <div style={{ padding: "10%", paddingTop: "0%" }}>
                  <Image src={envelope} width="100%" preview={false} />
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div style={{ paddingBottom: "5%" }}>
          <h2>02 AIRTIGHT CONSTRUCTION</h2>
          <p>
            Tested at 50 KPA, the building needs to be well wrapped inside and
            out to prevent air leakage stealing the heat from inside the
            building. Special tapes and wraps are used, the added bonus is that
            these are watertight also and give the buildings an extra layer of
            protection.
          </p>
        </div>
        <div>
          {height > width ? (
            <>
              <Row>
                <h2>03 NO THERMAL BRIDGING</h2>
                <p>
                  A thermal bridge is when a conductive element goes from the
                  interior to the exterior of the building. This could be a bolt
                  going through the framing to support another member, or a
                  copper pipe running through the wall. It also means that
                  standard aluminium windows will not perform in a Low energy
                  building.
                </p>
              </Row>
              <Row>
                <div style={{ padding: "10%", paddingTop: "0%" }}>
                  <Image src={windowBreak} width="100%" preview={false} />
                </div>
              </Row>
            </>
          ) : (
            <Row>
              <Col span={12}>
                <div style={{ padding: "10%", paddingTop: "0%" }}>
                  <Image src={windowBreak} width="100%" preview={false} />
                </div>
              </Col>
              <Col span={12}>
                <h2>03 NO THERMAL BRIDGING</h2>
                <p>
                  A thermal bridge is when a conductive element goes from the
                  interior to the exterior of the building. This could be a bolt
                  going through the framing to support another member, or a
                  copper pipe running through the wall. It also means that
                  standard aluminium windows will not perform in a Low energy
                  building.
                </p>
              </Col>
            </Row>
          )}
        </div>
        <h2>04 HEAT RECOVERY VENTILATION SYSTEM</h2>
        <p>
          This is key as all buildings need to breathe.This system takes warm,
          wet air from bathrooms and kitchens, transfers the heat to fresh air
          from outside before emitting the wet , used air outside. This means
          the occupant controls the humidity, temperature and cleanliness of the
          air, and all for less power usage than a small lightbulb! By
          constantly removing the old moist air we drastically reduce the risk
          of mould and mildew.
        </p>
      </div>
    </div>
  );
};
