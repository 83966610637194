import { Col, Divider, Image, Row } from "antd";
import { useRecoilValue } from "recoil";
import { windowState } from "../recoilState/recoilState";
import foam from "./1-2.png";
import ventilation from "./1-3.png";
import leaks from "./1.png";
import image from "./Home.png";

export const Home = () => {
  const { width, height } = useRecoilValue(windowState);

  return (
    <div>
      {height > width ? (
        <Row style={{ zIndex: 10, position: "relative", top: "50px" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image src={image} height="100%" preview={false} />
          </div>
        </Row>
      ) : (
        <Row style={{ zIndex: 10, position: "relative", top: "50px" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image src={image} width="100%" preview={false} />
          </div>
        </Row>
      )}

      <div
        style={{
          zIndex: 15,
          position: "relative",
          backgroundColor: "white",
          paddingLeft: width < 1300 ? "5%" : "20%",
          paddingRight: width < 1300 ? "5%" : "20%",
          paddingTop: "2rem",
        }}
      >
        <div
          style={{
            padding: "2%",
            paddingLeft: "5%",
            paddingBottom: "5%",
            backgroundColor: "white",
            borderRadius: "1rem",
          }}
        >
          <h1 style={{ fontWeight: "bolder" }}>Why Low Energy?</h1>
          <p>
            It’s our aim to elevate the building standard here in New Zealand.
            That’s why our properties are designed with German and Swedish
            principles to be healthier, energy-efficient and more sustainable.
            We use passive house principles to create warm healthy homes.
          </p>
          <Divider />
          {width > height ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/CasrjYhZB1M"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <iframe
                width={width}
                height={(width / 16) * 9}
                src="https://www.youtube.com/embed/CasrjYhZB1M"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
        {height > width ? (
          <div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "350px",
                  alignSelf: "center",
                }}
              >
                <Image src={foam} width="100%" preview={false} />
              </div>
              <h2>Top Quality Insulation</h2>
              <p>
                Our spray foam insulation not only keeps your home dry and warm
                it also is low allergen and environmentally friendly. Kiwi
                families deserve healthy, mould free homes.
              </p>
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "350px",
                  alignSelf: "center",
                }}
              >
                <Image src={leaks} width="100%" preview={false} />
              </div>
              <h2>Airtight Construction</h2>
              <p>
                Our specialised construction stops cold air from getting inside
                your home which reduces mould and protects you from losing
                warmth and wasting precious energy.
              </p>
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "350px",
                  alignSelf: "center",
                }}
              >
                <Image src={ventilation} width="100%" preview={false} />
              </div>
              <h2>Heat Recovery System</h2>
              <p>
                Making a home airtight stops cold, damp air from getting in
                however we also need to make sure you get fresh, warm air back
                inside which is why we use a specialised ventilation system.
              </p>
            </div>
          </div>
        ) : (
          <Row style={{ marginBottom: "2rem" }}>
            <Col span={8}>
              <div
                style={{
                  padding: "1rem",
                  borderRadius: ".25rem",
                  height: "100%",
                }}
              >
                <div style={{ padding: "1rem" }}>
                  <Image src={foam} width="100%" preview={false} />
                  <h2>Top Quality Insulation</h2>
                  <p>
                    Our spray foam insulation not only keeps your home dry and
                    warm it also is low allergen and environmentally friendly.
                    Kiwi families deserve healthy, mould free homes.
                  </p>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div
                style={{
                  padding: "1rem",
                  borderRadius: ".25rem",
                  height: "100%",
                  backgroundColor: "rgb(240,240,240)",
                }}
              >
                <div
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Image src={ventilation} width="100%" preview={false} />
                  <h2>Heat Recovery System</h2>
                  <p>
                    Making a home airtight stops cold, damp air from getting in
                    however we also need to make sure you get fresh, warm air
                    back inside which is why we use a specialised ventilation
                    system.
                  </p>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div
                style={{
                  padding: "1rem",
                  borderRadius: ".25rem",
                  height: "100%",
                }}
              >
                <div style={{ padding: "1rem" }}>
                  <Image src={leaks} width="100%" preview={false} />
                  <h2>Airtight Construction</h2>
                  <p>
                    Our specialised construction stops cold air from getting
                    inside your home which reduces mould and protects you from
                    losing warmth and wasting precious energy.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
