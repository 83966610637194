import { Carousel } from "antd";
import { useRecoilValue } from "recoil";
import { referencesState } from "../recoilState/recoilState";

export const Stories = () => {
  const clientStories = useRecoilValue(referencesState);
  return (
    <div className="BlockWidget">
      <h3>Client Stories</h3>
      <div>
        <Carousel
          autoplay
          dotPosition="bottom"
          speed={1000}
          autoplaySpeed={10000}
        >
          {clientStories.map((item, idx) => {
            return (
              <div key={idx}>
                <h4>{item.story}</h4>
                <h3 style={{ fontSize: ".75rem", paddingBottom: "2rem" }}>
                  {item.client}
                </h3>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
