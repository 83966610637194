import { collection, onSnapshot, query } from "firebase/firestore";
import { atom } from "recoil";
import { db } from "../link/link";
import {
  MessageArrayType,
  MessageType,
  ProjectArrayType,
  ProjectType,
  ReferencesArrayType,
  ReferenceType,
} from "../typeDefs";

export const windowState = atom({
  key: "windowState", // unique ID (with respect to other atoms/selectors)
  default: {
    width: window.visualViewport.width,
    height: window.visualViewport.height,
  }, // default value (aka initial value)
  effects: [
    ({ setSelf }) => {
      const listener = () => {
        setSelf({
          width: window.visualViewport.width,
          height: window.visualViewport.height,
        });
      };
      window.addEventListener("resize", listener);
      return () => {
        window.removeEventListener("resize", listener);
      };
    },
  ],
});

export const messagesState = atom({
  key: "messagesState", // unique ID (with respect to other atoms/selectors)
  default: [] as MessageArrayType, // default value (aka initial value)
  effects: [
    ({ setSelf }) => {
      const q = query(collection(db, "messages"));

      const unSub = onSnapshot(q, (docs) => {
        let newArray: MessageArrayType = [];
        docs.forEach((doc) => newArray.push(doc.data() as MessageType));
        setSelf(newArray);
      });
      return () => unSub();
    },
  ],
});

export const projectsState = atom({
  key: "projectsState", // unique ID (with respect to other atoms/selectors)
  default: [] as ProjectArrayType, // default value (aka initial value)
  effects: [
    ({ setSelf }) => {
      const q = query(collection(db, "projects"));

      const unSub = onSnapshot(q, (docs) => {
        let newArray: ProjectArrayType = [];
        docs.forEach((doc) => newArray.push(doc.data() as ProjectType));
        setSelf(newArray);
      });
      return () => unSub();
    },
  ],
});

export const referencesState = atom({
  key: "referencesState", // unique ID (with respect to other atoms/selectors)
  default: [] as ReferencesArrayType, // default value (aka initial value)
  effects: [
    ({ setSelf }) => {
      const q = query(collection(db, "references"));

      const unSub = onSnapshot(q, (docs) => {
        let newArray: ReferencesArrayType = [];
        docs.forEach((doc) => newArray.push(doc.data() as ReferenceType));
        setSelf(newArray);
      });
      return () => unSub();
    },
  ],
});
