import { LoginOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Image, Menu, Row, Space } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useAuth } from "../AuthProvider/AuthProvider";
import { CustomButton } from "../CustomButton/CustomButton";
import { windowState } from "../recoilState/recoilState";
import Logo from "./Black and white Modern Real estate logo video-3 (2).png";

export const MenuBar = () => {
  const { width } = useRecoilValue(windowState);
  const goToLocation = useNavigate();
  const { currentUser, logout } = useAuth();

  return (
    <div className="MenuBar">
      <Row>
        <Col span={4} onClick={() => goToLocation("/")}>
          {width < 1050 ? (
            <Image width={100} src={Logo} preview={false} />
          ) : (
            <Image width={200} src={Logo} preview={false} />
          )}
        </Col>
        <Col span={20}>
          {width < 1050 ? (
            <div>
              <Space
                align="center"
                style={{
                  alignSelf: "center",
                  float: "right",
                  //marginTop: "10px",
                  marginRight: "2px",
                }}
              >
                {/* <Button
                  onClick={() => (isMenu ? setIsMenu(false) : setIsMenu(true))}
                  icon={<MenuOutlined />}
                /> */}

                <Menu>
                  <SubMenu icon={<MenuOutlined />}>
                    <Menu.Item
                      style={{ fontSize: "10pt" }}
                      key={1}
                      onClick={() => goToLocation("/")}
                    >
                      HOME
                    </Menu.Item>
                    <Menu.Item
                      style={{ fontSize: "10pt" }}
                      key={2}
                      onClick={() => goToLocation("/current-projects")}
                    >
                      CURRENT PROJECTS
                    </Menu.Item>
                    <Menu.Item
                      style={{ fontSize: "10pt" }}
                      key={3}
                      onClick={() => goToLocation("/about-us")}
                    >
                      ABOUT US
                    </Menu.Item>
                    <Menu.Item
                      style={{ fontSize: "10pt" }}
                      key={4}
                      onClick={() => goToLocation("/how-it-works")}
                    >
                      HOW IT WORKS
                    </Menu.Item>
                    <Menu.Item
                      style={{ fontSize: "10pt" }}
                      key={5}
                      onClick={() => goToLocation("/passive-house")}
                    >
                      PASSIVE HOUSE
                    </Menu.Item>
                    <Menu.Item
                      style={{ fontSize: "10pt" }}
                      key={6}
                      onClick={() => goToLocation("/contact-us")}
                    >
                      CONTACT US
                    </Menu.Item>
                    {currentUser ? (
                      <Menu.Item key={7} onClick={() => logout()}>
                        LOGOUT
                      </Menu.Item>
                    ) : (
                      <Menu.Item key={7} onClick={() => goToLocation("/login")}>
                        LOGIN
                      </Menu.Item>
                    )}
                  </SubMenu>
                </Menu>
              </Space>
            </div>
          ) : (
            <div style={{ width: "100%", height: "100%" }}>
              <Space
                align="center"
                style={{
                  alignSelf: "center",
                  float: "right",
                  marginTop: "40px",
                }}
              >
                <CustomButton name="HOME" to="/" />
                <CustomButton name="CURRENT PROJECTS" to="/current-projects" />
                <CustomButton name="ABOUT US" to="/about-us" />
                <CustomButton name="HOW IT WORKS" to="/how-it-works" />
                {/* <CustomButton name="PASSIVE HOUSE" to="/passive-house" /> */}
                <CustomButton name="CONTACT US" to="/contact-us" />
                {currentUser ? (
                  <Button
                    type="text"
                    className="MenuButton"
                    onClick={() => logout()}
                  >
                    <LogoutOutlined />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    className="MenuButton"
                    onClick={() => goToLocation("/login")}
                  >
                    <LoginOutlined />
                  </Button>
                )}
              </Space>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
